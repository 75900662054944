export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    Submissions = 8,
    ArtworkProofs = 9,
    CanToggleCartridgeOptions = 10,
    CanManageEmailAddresses = 11,
    TrainingFileControls = 12
}
