import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';

import { AuthModule, AuthModuleConfigToken } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule, LoginModuleConfigToken } from '@mt-ng2/login-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';

import { AppRoutingModule } from './app-routing.module';
import { AdminPortalSharedModule } from '@common/shared.module';
import { AppComponent } from './app.component';
import { environment } from '@environment';
import { LoginConfigOverride } from '@common/configs/login.config';
import { AuthConfigOverride } from '@common/configs/auth.config';
import { SidebarContactInfoComponent } from './admin-portal/nav/sidebar-contact-info/sidebar-contact-info.component';
import { CookieModule } from '@mt-ng2/cookie';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, SidebarContactInfoComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        AdminPortalSharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        EnvironmentModule,
        NotificationsModule,
        AuthModule.forRoot(),
        MtDisableDuringHttpCallsModule.forRoot(),
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
    MtDisableDuringHttpCallsModule.forRoot()],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: AuthModuleConfigToken, useClass: AuthConfigOverride },
		{ provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {}
