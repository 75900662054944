import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDocument } from "@model/interfaces/document";
import { ITrainingFile } from "@model/interfaces/training-file";
import { BaseService } from "@mt-ng2/base-service";
import { Observable } from "rxjs";

@Injectable()
export class TrainingFileService extends BaseService<ITrainingFile> {
    constructor(http: HttpClient){
        super('/training-files', http);
    }

    addFile(name: string, file: File): Observable<void> {
        const form = new FormData();
        form.set("Name", name);
        form.append("file", file, file.name);
        return this.http.put<void>(`/training-files/add`, form);
    }

    downloadFile(id: number): Observable<Blob> {
        return this.http.get(`/training-files/${id}/download`, {
            responseType: 'blob'
        });
    }
}
