import { Injector, inject } from '@angular/core';
import { AuthConfig, IAuthConfig, IAuthConfigEventHandlers, IAuthConfigMessageOverrides, IAuthConfigPathOverrides } from '@mt-ng2/auth-module';
import { StorageService } from '@mt-ng2/common-classes';

export class AuthConfigOverride implements IAuthConfig {
    private storageService = inject(StorageService);
    eventHandlers: IAuthConfigEventHandlers = {
        onLogout: () => {
            void this.storageService.remove('saved-filters-submissions-list');
        },
    };
}
