import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { constants } from '@common/constants';
import { ITrainingFile } from '@model/interfaces/training-file';
import { TrainingFileService } from 'admin-portal/training-files/services/training-files.service';

@Component({
    selector: 'sidebar-contact-info',
    templateUrl: './sidebar-contact-info.component.html',
    styleUrls: ['./sidebar-contact-info.component.less'],
})
export class SidebarContactInfoComponent implements OnInit {
    instructionManualPath = constants.ARTWORK_PORTAL_INSTRUCTION_MANUAL_PATH;
    trainingVideoPath = constants.ARTWORK_PORTAL_TRAINING_VIDEO_PATH;
    trainingFiles: ITrainingFile[];

    @ViewChild('downloadAnchor')
    downloadAnchor: ElementRef;

    constructor(private trainingFileService: TrainingFileService) {}

    ngOnInit(): void {
        this.trainingFileService.getAll().subscribe((trainingFiles) => {
            this.trainingFiles = trainingFiles;
        });
    }

    downloadFile(file: ITrainingFile): void {
        this.trainingFileService.downloadFile(file.Id).subscribe((blob: Blob) => {
            const downloadAnchor = this.downloadAnchor.nativeElement as HTMLAnchorElement;
            downloadAnchor.href = window.URL.createObjectURL(blob);
            downloadAnchor.download = file.Document.Name;
            downloadAnchor.click();
        });
    }
}
