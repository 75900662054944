<hr />
<div id="contact-info-container">
    <h1>FISCHBACH CONTACT INFORMATION</h1>

    <h1>Graphics Department</h1>
    <p>
        Nancy Parr, Graphic Designer & Department Manager
        <br />
        Tel: 270-505-1254
        <br />
        Email: Graphics@fi-usa.com
    </p>

    <p>
        Sheronda Krentz, Graphic Designer
        <br />
        Tel: 270-769-9333 x274
        <br />
        Email: Graphics@fi-usa.com
    </p>

    <h1>Product Information & Pricing Requests</h1>
    <p>
        Crystal Lands, Customer Success Manager
        <br />
        Tel: 270-505-1253
        <br />
        Email: crystal.lands@fi-usa.com
    </p>

    <p>
        Genna Duvall, Pricing & Process Improvement Specialist
        <br />
        Tel: 270-506-1460
        <br />
        Email: genna.duvall@fi-usa.com
    </p>

    <h1>Ordering, Status Requests, and General Information</h1>
    <p>
        Fischbach USA Customer Success Team
        <br />
        Tel: 270-769-9333
        <br />
        Email: CustomerService@fi-usa.com
    </p>

    <h1>REFERENCE MATERIAL</h1>
    <p>
        <ng-container *ngFor="let file of trainingFiles">
            <a class="cursor-pointer" (click)="downloadFile(file)">{{file.Name}}</a>
            <br />
        </ng-container>
    </p>
</div>
<a style="display: none;" download #downloadAnchor></a>
